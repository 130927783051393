import React from "react";
import { useRouter } from "next/router";
import isEmpty from "lodash/isEmpty";
import {
  Button,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  Image,
  Progress,
  CircularProgress,
} from "@gonoodle/gn-universe-ui";
import { Popover } from "@gonoodle/gn-universe-ui/lib/next";
import get from "lodash/get";
import LottiePlayer from "react-lottie-player/dist/LottiePlayerLight";
import { ExternalLinkIcon } from "@heroicons/react/outline";

import { twMerge } from "tailwind-merge";
import ChampChatBubble from "./ChampChatBubble";
import PointsProgressBar from "../PointsProgressBar";
import { useUser } from "../../contexts/user";
import {
  useChampionsQuery,
  useCurrentChampion,
  useProfile,
  useMonthlyBadgesQuery,
  useBadgeRulesProgress,
  useBadgeRulesCombinedProgress,
  useBadgeClaimCount,
  useRegularBadgesQuery,
  useEarnedBadges,
} from "../../hooks";
import { useTransmogrifier } from "../../contexts/Transmogrifier";
import { useLogEvent } from "../../contexts/Analytics";
import { getUtmParamsFromQueryParams } from "../../utils/analytics";
import { pauseAllJWPlayers } from "../../utils/jwplayer";
import {
  VIDEO_STATES,
  ROUTE_PREFIX,
  ROUTE_PATHS,
  BADGE_EARNING_MODELS,
} from "../../constants";
import trophyEarnedAnimation from "../../public/lottie/notifications/trophy-earned.json";
import TrophyAvatar from "../TrophyAvatar";
import Link from "../Link";

function TrophyIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12.375 14.063h-6.75m6.75 0a2.25 2.25 0 0 1 2.25 2.25H3.375a2.25 2.25 0 0 1 2.25-2.25m6.75 0V11.53a.844.844 0 0 0-.844-.844h-.653m-5.253 3.376V11.53c0-.466.378-.844.844-.844h.654m3.755 0H7.123m3.755 0a5.59 5.59 0 0 1-.736-2.378m-3.02 2.379a5.59 5.59 0 0 0 .736-2.38m2.284 0a5.042 5.042 0 0 0 2.06-1.012m-2.06 1.013a5.079 5.079 0 0 1-2.284 0m0 0a5.045 5.045 0 0 1-2.06-1.013m-1.86-4.119c-.737.107-1.466.238-2.188.39a4.502 4.502 0 0 0 4.047 3.729m-1.86-4.119v.198c0 1.581.725 2.992 1.86 3.921m-1.86-4.119V2.041A36.254 36.254 0 0 1 9 1.688c1.718 0 3.409.12 5.063.352v1.137m0 0v.198a5.052 5.052 0 0 1-1.86 3.921m1.86-4.119c.732.107 1.462.237 2.187.39a4.502 4.502 0 0 1-4.047 3.729"
      />
    </svg>
  );
}

function MonthlyChallenge() {
  const { monthlyBadges = [] } = useMonthlyBadgesQuery();
  const latestBadge = React.useMemo(
    () =>
      monthlyBadges
        .filter((badge) => new Date(badge.publishStartsAt) <= new Date())
        .sort(
          (a, b) => new Date(b.publishStartsAt) - new Date(a.publishStartsAt),
        )[0],
    [monthlyBadges],
  );
  const badgeRulesProgress = useBadgeRulesProgress({ id: latestBadge?.id });

  if (!latestBadge || !badgeRulesProgress || badgeRulesProgress.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-3 py-4 first:pt-0 last:pb-0">
      <span className="text-sm text-gray-200 font-bold">Monthly Challenge</span>

      {badgeRulesProgress.map(({ ruleId, currentScore, scoreNeeded }) => {
        const rule = latestBadge.rules.find(({ id }) => id === ruleId);

        return (
          <Progress
            key={ruleId}
            value={currentScore}
            maxValue={scoreNeeded}
            label={rule.title}
            valueLabel={`${currentScore} / ${scoreNeeded}`}
            className="text-gray-200"
          />
        );
      })}
    </div>
  );
}

const BadgeCircularProgress = ({ id, children }) => {
  const { combinedScore, combinedScoreNeeded } = useBadgeRulesCombinedProgress({
    id,
  });

  return (
    <CircularProgress
      value={combinedScore}
      maxValue={combinedScoreNeeded}
      thickness={3}
    >
      {children}
    </CircularProgress>
  );
};

const BadgeAvatar = ({ title, isClaimed, earnedImage, defaultImage }) => (
  <TrophyAvatar
    className="w-[60px] h-[60px]"
    src={isClaimed ? earnedImage.regular3x : defaultImage.regular3x}
    imageProps={{
      width: 60,
      height: 60,
      fill: false,
    }}
    fallback={title}
  />
);

const BadgeClaimCount = ({ id, earningModel }) => {
  const claimCount = useBadgeClaimCount({ id });

  return (
    claimCount > 0 &&
    earningModel === BADGE_EARNING_MODELS.RECURRING && (
      <span className="flex items-center justify-center w-4 h-4 bg-white p-2 rounded-full text-black text-xs font-extrabold">
        {claimCount}
      </span>
    )
  );
};

function Badge({
  id,
  title,
  isClaimed,
  isEarned,
  earnedImage,
  defaultImage,
  earningModel,
}) {
  return (
    <Link
      to={`/${ROUTE_PREFIX.TROPHIES}`}
      className="relative flex flex-col space-y-1 w-min"
    >
      <BadgeCircularProgress id={id}>
        <div className="relative">
          <BadgeAvatar
            title={title}
            isClaimed={isClaimed}
            isEarned={isEarned}
            earnedImage={earnedImage}
            defaultImage={defaultImage}
          />
        </div>

        {isEarned && (
          <div className="absolute inset-0 flex justify-center items-center">
            <div className="bg-purple p-1 rounded shrink-0">
              <TrophyIcon width={18} height={18} />
            </div>
          </div>
        )}
      </BadgeCircularProgress>

      <span className="text-xs text-white font-semibold text-center">
        {title}
      </span>

      <div className="absolute right-0 top-1/5">
        <BadgeClaimCount id={id} earningModel={earningModel} />
      </div>
    </Link>
  );
}

function MonthlyBadges() {
  const { monthlyBadges = [] } = useMonthlyBadgesQuery();

  if (monthlyBadges.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-3 py-4 first:pt-0 last:pb-0">
      <span className="text-sm text-gray-200 font-bold">Monthly Badges</span>

      <div className="grid grid-cols-4 gap-2">
        {monthlyBadges.map(
          ({
            id,
            title,
            isClaimed,
            isEarned,
            earnedImage,
            defaultImage,
            earningModel,
          }) => (
            <Badge
              key={id}
              id={id}
              title={title}
              isClaimed={isClaimed}
              isEarned={isEarned}
              earnedImage={earnedImage}
              defaultImage={defaultImage}
              earningModel={earningModel}
            />
          ),
        )}
      </div>
    </div>
  );
}

function RegularBadges() {
  const { regularBadges = [] } = useRegularBadgesQuery();

  if (regularBadges.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-3 py-4 first:pt-0 last:pb-0">
      <span className="text-sm text-gray-200 font-bold">Regular Badges</span>

      <div className="grid grid-cols-4 gap-2">
        {regularBadges.map(
          ({
            id,
            title,
            isClaimed,
            isEarned,
            earnedImage,
            defaultImage,
            earningModel,
          }) => (
            <Badge
              key={id}
              id={id}
              title={title}
              isClaimed={isClaimed}
              isEarned={isEarned}
              earnedImage={earnedImage}
              defaultImage={defaultImage}
              earningModel={earningModel}
            />
          ),
        )}
      </div>
    </div>
  );
}

function BadgesQuickAccess({ playbackState }) {
  const lottiePlayerRef = React.useRef();
  const router = useRouter();
  const champion = useCurrentChampion();
  const { points } = useTransmogrifier();
  const { user } = useUser();
  const { earnedBadges } = useEarnedBadges();

  const showTrophyAnimation =
    !isEmpty(earnedBadges) && playbackState !== VIDEO_STATES.PLAYING;
  const showEmptyTrophy = isEmpty(earnedBadges);

  React.useEffect(() => {
    // This useEffect hook adjusts the fill color of the SVG path within the Lottie animation
    // based on the currentPill value reaching maxValue. The SVG path is targeted directly
    // via DOM manipulation because Lottie does not provide a built-in method to programmatically
    // change properties post-render. This approach requires knowing the specific structure
    // and styles of the 'trophyEarnedAnimation'.
    //
    // Important: The fill color values "#9258ff" (purple-400) for the maxValue and "#3d1687" for other values
    // are hardcoded based on the current design of the 'trophyEarnedAnimation'. If the animation
    // file changes or the design of the animation is updated, these fill values must be reviewed
    // and potentially updated to match the new animation design. Always verify that the SVG path
    // still exists and that the fill colors align with the new design aesthetics.

    if (lottiePlayerRef.current) {
      const svgPath = lottiePlayerRef.current.wrapper.querySelector("svg path");
      if (svgPath) {
        svgPath.style.transition = "fill 1s 1s";

        svgPath.style.fill =
          points >= champion.pointsPerLevel ? "#9258ff" : "#3d1687";
      }

      if (showEmptyTrophy) {
        const cupGroup = lottiePlayerRef.current.wrapper.querySelector("#cup");

        Array.from(cupGroup.children)
          .slice(0, 2) // Take only the first two children
          .forEach((child) => {
            child.setAttribute("opacity", "0"); // Set opacity to 0
          });
      }
    }
  }, [points, champion.pointsPerLevel, showEmptyTrophy]);

  return (
    <Popover.Root>
      <Popover.Trigger className="self-end h-12 w-fit outline-none translate-y-[1px] lg:-translate-x-4">
        <LottiePlayer
          ref={lottiePlayerRef}
          animationData={trophyEarnedAnimation}
          loop={showTrophyAnimation}
          play={showTrophyAnimation}
          className="h-full w-full"
        />
      </Popover.Trigger>

      <Popover.Panel
        avoidCollisions={false} // To avoid flipping the popover when it's near the top edge of the screen.
        side="top"
        align="end"
        className="outline-none w-[calc(100vw-48px)] md:w-fit z-50" // 48px is the padding of the container, and z-50 is to ensure the popover is above the nav bar.
      >
        {/* Using translate-y-8 to account for the translate of the trigger container. */}
        {/* Using translate-x-4 to account for the translate of the trigger. */}
        <div className="h-[420px] -translate-y-8 md:translate-y-0 lg:translate-x-4 overflow-y-scroll rounded-xl py-2 px-4 bg-black md:w-max md:min-w-[345px]">
          {user.isLoggedIn ? (
            <div className="flex flex-col space-y-4">
              <Link to={`/${ROUTE_PREFIX.TROPHIES}`} className="self-end">
                <ExternalLinkIcon
                  width={20}
                  height={20}
                  className="text-white"
                />
              </Link>

              <div className="flex flex-col divide-y-2 divide-gray-500">
                <MonthlyChallenge />

                <MonthlyBadges />

                <RegularBadges />
              </div>
            </div>
          ) : (
            <div className="flex flex-col h-full items-center justify-center space-y-4">
              <span className="text-sm text-gray-200 text-center font-bold">
                Create an Account or Log in to earn badges!
              </span>

              <Button
                className="w-full"
                variant={BUTTON_VARIANTS.vivid}
                size={BUTTON_SIZES.md}
                onPress={() => router.push(ROUTE_PATHS.REGISTRATION)}
              >
                Create a Free Account
              </Button>

              <Button
                variant={BUTTON_VARIANTS.lightOutline}
                size={BUTTON_SIZES.md}
                onPress={() => router.push(ROUTE_PATHS.LOGIN)}
                className="w-full"
              >
                Log In
              </Button>
            </div>
          )}
        </div>
      </Popover.Panel>
    </Popover.Root>
  );
}

export default function ChampProgressBar({ videoState }) {
  const { user } = useUser();
  const router = useRouter();

  const champion = useCurrentChampion();
  const { champions } = useChampionsQuery();
  const { profile } = useProfile();
  const {
    points,
    earnedPoints,
    canGraduate,
    canLevelUp,
    canEarnPoints,
    canRegister,
    transitionTypes,
    transition,
    setTransition,
    levelUpChamp,
    earnPoints,
    redirectToRegistration,
  } = useTransmogrifier();

  const isLoggedOutUser = !user.isLoggedIn;
  const videoIsPaused = [VIDEO_STATES.COMPLETED, VIDEO_STATES.PAUSED].includes(
    videoState,
  );

  const phaseTransitionImage = React.useMemo(() => {
    if (!champion || !champions) return null;

    const currentChampionWithImages = champions.find(
      (champ) => champ.id === champion.champId,
    );

    if (isLoggedOutUser) {
      return get(currentChampionWithImages, "images.phase2.full", null);
    }

    return get(
      currentChampionWithImages,
      ["images", `phase${champion.level + 1}`, "full"],
      null,
    );
  }, [champion, champions, isLoggedOutUser]);

  useLogEvent({
    event: "Unregistered Champ Achievement",
    properties: {
      ...getUtmParamsFromQueryParams(router.query),
    },
    options: {
      referrer: () => {},
      enabled:
        champion && transition === transitionTypes.pointAdded && canRegister,
    },
  });

  useLogEvent({
    event: "Reach Champ Achievement",
    properties: {
      champName: champion?.name,
      level: String(champion?.level),
    },
    options: {
      referrer: () => {},
      enabled:
        champion && transition === transitionTypes.pointAdded && canLevelUp,
    },
  });

  const { logEvent: logLevelUpVideoSkippedEvent } = useLogEvent({
    event: "Level Up Video Skipped",
    options: {
      referrer: () => {},
    },
  });

  const { logEvent: logLevelUpVideoEvent } = useLogEvent({
    event: "Level Up Video",
    options: {
      referrer: () => {},
    },
  });

  const handleAddPoints = () => {
    pauseAllJWPlayers();
    setTransition(transitionTypes.pointAdded);
    earnPoints();
  };

  const levelUpChampion = React.useCallback(
    (skip) => {
      if (skip === true) {
        levelUpChamp();
        logLevelUpVideoSkippedEvent({
          newChampLevel: String(champion.level + 1),
        });
      } else {
        pauseAllJWPlayers();
        setTransition(transitionTypes.levelUp);
        logLevelUpVideoEvent({ newChampLevel: String(champion.level + 1) });
      }
    },
    [
      levelUpChamp,
      logLevelUpVideoSkippedEvent,
      champion?.level,
      setTransition,
      transitionTypes.levelUp,
      logLevelUpVideoEvent,
    ],
  );

  const graduateChampion = React.useCallback(() => {
    pauseAllJWPlayers();
    setTransition(transitionTypes.graduate);
  }, [setTransition, transitionTypes.graduate]);

  if (!champion) {
    return null;
  }

  return (
    <section className="sticky bottom-0 order-last z-20 bg-black">
      <div className="container relative">
        {!transition && videoState && canEarnPoints && videoIsPaused && (
          <ChampChatBubble
            message={`You've earned ${earnedPoints} point${
              earnedPoints > 1 ? "s" : ""
            }! Watch your Champ add points to the progress bar now.`}
          >
            <Button
              className="mt-4 w-full"
              variant={BUTTON_VARIANTS.vivid}
              size={BUTTON_SIZES.md}
              onPress={handleAddPoints}
            >
              Add Point{earnedPoints > 1 ? "s" : ""}
            </Button>
          </ChampChatBubble>
        )}

        {!transition && canLevelUp && (
          <ChampChatBubble
            message={`Nice job! You got all ${champion.pointsPerLevel} points!`}
            imageSources={
              phaseTransitionImage
                ? {
                    "regular@1x": phaseTransitionImage["1x"],
                    "regular@2x": phaseTransitionImage["2x"],
                    "regular@3x": phaseTransitionImage["3x"],
                  }
                : null
            }
          >
            <Button
              className="mt-4 w-full"
              variant={BUTTON_VARIANTS.vivid}
              size={BUTTON_SIZES.md}
              onPress={levelUpChampion}
            >
              Level Up!
            </Button>

            <Button
              className="mt-4 w-full"
              variant={BUTTON_VARIANTS.darkOutline}
              size={BUTTON_SIZES.md}
              onPress={() => {
                levelUpChampion(true);
              }}
            >
              Skip
            </Button>
          </ChampChatBubble>
        )}

        {!transition && canGraduate && (
          <ChampChatBubble
            message="Congratulations! You've maxed out your Champ"
            imageSources={
              phaseTransitionImage
                ? {
                    "regular@1x": phaseTransitionImage["1x"],
                    "regular@2x": phaseTransitionImage["2x"],
                    "regular@3x": phaseTransitionImage["3x"],
                  }
                : null
            }
          >
            <Button
              className="mt-4 w-full"
              variant={BUTTON_VARIANTS.vivid}
              size={BUTTON_SIZES.md}
              onPress={graduateChampion}
            >
              Graduate Champ
            </Button>
          </ChampChatBubble>
        )}

        {!transition && canRegister && (
          <ChampChatBubble
            message="Want to continue the fun? Create your free account now to earn points, grow your Champ, and save your favorite videos!"
            imageSources={
              phaseTransitionImage
                ? {
                    "regular@1x": phaseTransitionImage["1x"],
                    "regular@2x": phaseTransitionImage["2x"],
                    "regular@3x": phaseTransitionImage["3x"],
                  }
                : null
            }
          >
            <Button
              className="mt-4 w-full"
              variant={BUTTON_VARIANTS.vivid}
              size={BUTTON_SIZES.md}
              onPress={redirectToRegistration}
            >
              Create a Free Account
            </Button>
          </ChampChatBubble>
        )}
      </div>

      <div className="container py-4 relative flex flex-col md:flex-row md:space-x-8 md:items-center md:py-0 md:static">
        <div
          className={twMerge(
            "space-x-3 flex items-center shrink-0",
            !!user.features.useBadgesProgressBar && "absolute md:static",
          )}
        >
          <div className="h-10">
            <Image
              className="w-full h-full object-contain"
              sources={{
                "regular@1x": champion.images.headshot["1x"],
                "regular@2x": champion.images.headshot["2x"],
                "regular@3x": champion.images.headshot["3x"],
              }}
              alt=""
            />
          </div>

          <div className="flex flex-col">
            {user.isLoggedIn && (
              <span className="text-xs text-purple-300 font-bold lg:text-sm">
                Level {`${champion.level}/${5}`}
              </span>
            )}

            <span className="text-xs font-bold text-white lg:text-md">
              {champion.name}
            </span>

            <span className="text-xs text-gray-200 truncate max-w-1/2 md:max-w-full">
              {isLoggedOutUser ? "Level 1" : profile.name}
            </span>
          </div>
        </div>

        <div
          className={twMerge(
            "flex flex-col w-full my-4",
            !!user.features.useBadgesProgressBar &&
              "my-0 mt-4 md:-translate-y-1/4 md:mt-0",
          )}
        >
          {user.features.useBadgesProgressBar && (
            <BadgesQuickAccess playbackState={videoState} />
          )}

          <PointsProgressBar value={points} max={champion.pointsPerLevel} />
        </div>
      </div>
    </section>
  );
}
